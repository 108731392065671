"use client";

import classNames from "classnames";
import { Colors } from "components/colors";
import { Font } from "components/font";
import { radius } from "libs/styles/variables";
import { memo, ReactNode } from "react";
import styled, { css } from "styled-components";

type themeList =
  | "bare"
  | "primary"
  | "primary_hollow"
  | "secondary"
  | "secondary_hollow"
  | "danger"
  | "danger_hollow"
  | "muted";

type Props = {
  statusTheme?: themeList;
  className?: string;
  children: ReactNode;
};

export const Status = memo(function Status({
  statusTheme: theme = "bare",
  className,
  children,
}: Props) {
  return (
    <Base $statusTheme={theme} className={classNames(className)}>
      {children}
    </Base>
  );
});

const ThemeStyle = css<{ $statusTheme: themeList }>`
  ${({ $statusTheme }) => {
    switch ($statusTheme) {
      case "bare":
        return `
        color: ${Colors.black87};
        background: white;
        border-color: white;
      `;
      case "primary":
        return `
        color: ${Colors.primary};
        background: ${Colors.primaryLight2};
        border-color: ${Colors.primary};
      `;
      case "primary_hollow":
        return `
        color: ${Colors.primary};
        background: ${Colors.white100};
        border-color: ${Colors.primary};
      `;
      case "secondary":
        return `
        color: ${Colors.secondary};
        background: ${Colors.secondaryLight2};
        border-color: ${Colors.secondary};
      `;
      case "secondary_hollow":
        return `
        color: ${Colors.secondary};
        background: ${Colors.white100};
        border-color: ${Colors.secondary};
      `;
      case "danger":
        return `
        color: ${Colors.alert};
        background: ${Colors.alertLight2};
        border-color: ${Colors.alert};
      `;
      case "danger_hollow":
        return `
        color: ${Colors.alert};
        background: ${Colors.white100};
        border-color: ${Colors.alert};
      `;
      case "muted":
        return `
        color: ${Colors.black87};
        background: ${Colors.black12};
        border-color: ${Colors.black87};
      `;
    }
  }}
`;

type themedComponentProps = { $statusTheme: themeList };

const Base = styled.span<themedComponentProps>`
  border: 1px solid transparent;
  border-radius: ${radius.sm};
  box-sizing: border-box;
  display: inline-block;
  font-size: ${Font.fontSizeXs};
  line-height: ${Font.fontSizeXs};
  font-family: ${Font.fontFamilyDefault};
  padding: 6px 6px;
  min-width: 76px;
  text-align: center;

  ${ThemeStyle}
`;
