"use client";

import classNames from "classnames";
import { radius } from "libs/styles/variables";
import { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  children: ReactNode;
  className?: string;
  isOverflow?: boolean;
};

export function Card({ children, className, isOverflow = false }: Props) {
  return (
    <Base className={classNames(className)} $isOverflow={isOverflow}>
      {children}
    </Base>
  );
}

const Base = styled.div<{ $isOverflow: boolean }>`
  background: white;
  border-radius: ${radius.md};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  min-height: 100%;
  width: 100%;

  ${({ $isOverflow }) => $isOverflow && "overflow: visible;"}
`;
