// APIErrorをinstance ofで判定しているコードがあるため、新しいクラスで返してしまうと予想外の結果につながる可能性があるので、まずはインポートしておく
import { APIError } from "libs/api/client";
import { stringify } from "qs";
import {
  ApiBody,
  ApiPath,
  ApiPathParam,
  ApiQueryParam,
  ApiResponse,
  HttpMethod,
} from "./schema-utils";

type InputArguments = {
  signal?: AbortSignal;
  formData?: FormData;
  cookie?: string;
};

export type TypedResponse<JSONType> = Omit<Response, "json"> & {
  json(): Promise<JSONType>;
};

export const request = async <Method extends HttpMethod, Path extends ApiPath>(
  method: Method,
  path: Path,
  params?: InputArguments & {
    path?: ApiPathParam<Path, Method>;
    query?: ApiQueryParam<Path, Method>;
    body?: ApiBody<Path, Method>;
  }
): Promise<TypedResponse<ApiResponse<Path, Method>>> => {
  const requestPrefix =
    typeof window !== "undefined" ? "/api" : process.env.API_ENDPOINT;
  let finalPath = `${path}`;
  if (params?.path) {
    for (const [k, v] of Object.entries(params?.path ?? {}))
      finalPath = finalPath.replace(`{${k}}`, encodeURIComponent(String(v)));
  }
  let fullUrl = `${requestPrefix}${finalPath}`;
  if (params?.query) {
    fullUrl += `?${stringify(params?.query)}`;
  }

  const controller =
    typeof window !== "undefined"
      ? new AbortController()
      : new (class {
          public signal = null;
          public abort() {}
        })();
  const headers = {
    Accept: "application/json",
    ...(!params?.formData ? { "Content-Type": "application/json" } : {}),
    ...(params?.cookie ? { Cookie: params?.cookie } : {}),
  };
  if (params?.formData) {
    params?.formData.append(
      "jsonData",
      JSON.stringify("body" in params ? params?.body : undefined)
    );
  }
  const init = {
    // HTTPメソッドのpatchだけは、自動変換されないので全てのメソッドを大文字に変換する
    method: method.toUpperCase(),
    headers,
    mode: "cors",
    ...(params?.formData
      ? { body: params?.formData }
      : params?.body
      ? { body: JSON.stringify(params?.body) }
      : {}),
    signal: params?.signal || controller.signal,
    credentials: "include",
  };
  const response = await fetch(fullUrl, init as any).catch((e) => {
    throw new APIError({ abort: e.name === "AbortError" });
  });
  if (response.ok) {
    return response;
  }
  const json = await response.json().catch(() => {
    throw new APIError({ response });
  });
  const type = json.error?.type;
  const details = json.error?.details;

  if (typeof type === "string") {
    throw new APIError({ response, type, details });
  } else {
    throw new APIError({ response });
  }
};
