"use client";

import classNames from "classnames";
import { spacing } from "libs/styles/variables";
import { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  children: ReactNode;
  className?: string;
  "data-testid"?: string;
};

export function CardSection({
  children,
  className,
  "data-testid": testId,
}: Props) {
  return (
    <Base className={classNames(className)} data-testid={testId}>
      {children}
    </Base>
  );
}

const Base = styled.section`
  padding: ${spacing.xl};

  & + & {
    padding-top: 0;
  }
`;
