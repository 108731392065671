"use client";

import classNames from "classnames";
import { ComponentProps, memo } from "react";
import styled from "styled-components";
import { Status } from ".";

type Props = Omit<ComponentProps<typeof Status>, "theme" | "children"> & {
  type: "require" | "optional";
};

const typeToTheme = {
  require: "danger",
  optional: "primary",
} as const;
const typeToChildren = {
  require: "必須",
  optional: "任意",
} as const;

export const RequirementStatus = memo(function RequirementStatus({
  className,
  type,
  ...otherProps
}: Props) {
  return (
    <StatusWrapper
      className={classNames(className)}
      statusTheme={typeToTheme[type]}
      {...otherProps}
    >
      {typeToChildren[type]}
    </StatusWrapper>
  );
});

const StatusWrapper = styled(Status)`
  min-width: 40px;
  padding: 4px 8px;
`;
